import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { APP_CONFIG } from '../assets/config';
import { HeaderComponent } from './shell/header/header.component';
import { SidebarComponent } from './shell/sidebar/sidebar.component';
import { SignOutComponent } from './shell/sign-out/sign-out.component';
import { SignInComponent } from './shell/sign-in/sign-in.component';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { NgHttpCachingService } from 'ng-http-caching';
import { RootCatalogComponent } from './shell/root-catalog/root-catalog.component';
import { SearchStateService } from './shared/services/search-state.service';
import { StacObjectStateService } from './shared/services/stac-object-state.service';

/**
 * The root component for the application. Builds up the page structure and
 * navigation components.
 */
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SignInComponent,
    SignOutComponent,
    MatDialogModule,
    HeaderComponent,
    SidebarComponent,
    MatIconModule,
    RootCatalogComponent,
  ],
  providers: [MatIconRegistry],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnDestroy {
  @ViewChild(SidebarComponent) sidebar?: SidebarComponent;
  signInRedirectUrl = '/';
  readonly appName = APP_CONFIG.appName;
  readonly defaultCatalog = APP_CONFIG.rootCatalog;
  readonly mobileSize = APP_CONFIG.mobileSize;
  readonly headerHeight = APP_CONFIG.headerHeight;
  private stacObjectState = inject(StacObjectStateService);
  private searchState = inject(SearchStateService);

  public authenticator = inject(AuthenticatorService);
  public dialog = inject(MatDialog);
  private titleService = inject(Title);
  private router = inject(Router);
  private iconRegistry = inject(MatIconRegistry);
  private httpCache = inject(NgHttpCachingService);

  private authSubscription = this.authenticator.subscribe((event: any) => {
    if (event.route == 'authenticated' && event.authStatus === 'authenticated') {
      this.clearState();
      this.router.navigateByUrl(this.signInRedirectUrl);
    } else if (event.route == 'signOut' && event.authStatus === 'unauthenticated') {
      this.clearState();
      this.router.navigateByUrl(this.signInRedirectUrl);
    }
  });

  private routeSubscription = this.router.events.subscribe(() => {
    if (this.isMobile()) {
      this.sidebar?.toggle(false);
    }
  });

  private clearState() {
    this.httpCache.clearCache();
    this.stacObjectState.clear();
    this.searchState.clear();
  }

  constructor() {
    this.titleService.setTitle(this.appName);
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  signInAction() {
    this.signInRedirectUrl = this.router.routerState.snapshot.url;
    this.router.navigate(['/signin']);
  }

  signOutAction() {
    this.signInRedirectUrl = this.router.routerState.snapshot.url;
    this.authenticator.signOut();
  }

  isMobile(): boolean {
    return window.innerWidth <= this.mobileSize;
  }

  rootCatalog() {
    let currentCatalog = localStorage.getItem('rootCatalog');
    if (!currentCatalog) {
      currentCatalog = this.defaultCatalog;
    }

    const rootCatalogDialog = this.dialog.open(RootCatalogComponent, {
      width: '800px',
      maxWidth: 'calc(100% - 20px)',
      position: { top: `${this.headerHeight}px`, right: '10px' },
      data: { url: currentCatalog },
    });

    const beforeNavUrl = this.router.url.split('?')[0];
    rootCatalogDialog.afterClosed().subscribe((result) => {
      if (result) {
        localStorage.setItem('rootCatalog', result);
        this.clearState();
        this.stacObjectState.load(result);
        this.router.navigateByUrl(beforeNavUrl);
      }
    });
  }
}
