import { Component, ViewChild, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { RouterModule, RouterLinkActive } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { APP_CONFIG } from '../../../assets/config';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    RouterLinkActive,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css',
})
export class SidebarComponent {
  isMobile = input<boolean>(false);
  headerHeight = input<number>(56);

  helpUrl = APP_CONFIG.helpUrl;
  analysysUrl = APP_CONFIG.analysysHubUrl;

  @ViewChild('sidebar') sidebar!: MatSidenav;

  toggle(state?: boolean) {
    if (state === undefined) {
      this.sidebar.toggle();
    } else {
      if (state) {
        this.sidebar.open();
      } else {
        this.sidebar.close();
      }
    }
  }
}
