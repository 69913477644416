import { Component, Output, EventEmitter, input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltip,
    MatButtonModule,
    RouterLink,
    NgOptimizedImage,
    MatMenuModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  isMobile = input<boolean>(false);
  showMenu = input<boolean>(true);
  height = input<number>(56);
  appLogo = input.required<string>();
  appName = input.required<string>();
  userName = input.required<string>();

  @Output() menuClickEvent = new EventEmitter<void>();
  @Output() signInClickEvent = new EventEmitter<boolean>();
  @Output() rootCatalogClickEvent = new EventEmitter<void>();

  /**
   * Emit the menuClickEvent when the menu button is clicked.
   */
  clickMenu() {
    this.menuClickEvent.emit();
  }

  /**
   * Emit the signInClickEvent when the sign in button is clicked.
   * Emits true to sign in, false to sign out.
   */
  clickSignIn($event: boolean) {
    this.signInClickEvent.emit($event);
  }

  clickRootCatalog() {
    this.rootCatalogClickEvent.emit();
  }
}
