import { Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface RootCatalogData {
  url: string;
}

@Component({
  selector: 'app-root-catalog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './root-catalog.component.html',
  styleUrl: './root-catalog.component.css',
})
export class RootCatalogComponent {
  dialogRef = inject(MatDialogRef<RootCatalogComponent>);
  constructor(@Inject(MAT_DIALOG_DATA) public data: RootCatalogData) {}
}
