import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../assets/config';

/**
 * Interceptor used to intercept HTTP requests and add the authorization headers.
 */
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user = localStorage.getItem(
      `CognitoIdentityServiceProvider.${APP_CONFIG.cognitoUserPoolClientId}.LastAuthUser`
    );
    if (user) {
      let token = localStorage.getItem(
        `CognitoIdentityServiceProvider.${APP_CONFIG.cognitoUserPoolClientId}.${user}.idToken`
      );
      req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }
    return next.handle(req);
  }
}
