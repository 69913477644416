<mat-toolbar class="header" #header [class.show-menu]="showMenu()"
  [ngStyle]="{'--mat-toolbar-mobile-height': height() +'px', '--mat-toolbar-standard-height': height() +'px'}">
  <button class="header-item menu-button" mat-icon-button (click)="clickMenu()">
    <mat-icon>menu</mat-icon></button>
  <a class="header-item logo" mat-button href="/" routerLink="/">
    <div class="header-item-container">
      <img class="header-icon" [ngSrc]="appLogo()" priority width="100" height="100" />
      <span class="header-text logo-text">{{appName()}}</span>
    </div>
  </a>
  <div class="spacer"></div>
  @if (userName()){
  <span class="header-text">{{userName()}}</span>
  }
  <button class="header-item" mat-button matTooltip="Settings" [matMenuTriggerFor]="menu">
    <div class="header-item-container">
      <mat-icon class="header-icon settings-icon">settings</mat-icon>
    </div>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="clickRootCatalog()"><mat-icon>flag</mat-icon>Root
      Catalog</button>
    @if (userName()){
    <button mat-menu-item (click)="clickSignIn(false)"><mat-icon>logout</mat-icon>Sign Out</button>
    } @else {
    <button mat-menu-item (click)="clickSignIn(true)"><mat-icon>login</mat-icon>Sign In</button>
    }
  </mat-menu>
</mat-toolbar>