<div class="container" [style.top]="headerHeight() + 'px'"
  [style.height]="'calc(100% - ' + headerHeight() + 'px)'">
  <mat-sidenav-container class="sidebar-container" [class.is-mobile]="isMobile()">
    <mat-sidenav #sidebar [opened]="!isMobile()" [mode]="isMobile() ? 'over' : 'side'"
      [fixedInViewport]="isMobile()" [fixedTopGap]="headerHeight()">
      <mat-nav-list>
        <a mat-list-item routerLink='/' [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{exact: true}">
          <div class="sidebar-item">
            <mat-icon matListItemIcon>home</mat-icon>
            <span>Home</span>
          </div>
        </a>
        <a mat-list-item routerLink='/catalog' [routerLinkActive]="'active'">
          <div class="sidebar-item"><mat-icon
              matListItemIcon>library_books</mat-icon><span>Catalog</span></div>
        </a>
        <a mat-list-item routerLink='/search' [routerLinkActive]="'active'">
          <div class="sidebar-item"><mat-icon matListItemIcon>search</mat-icon><span>Search</span>
          </div>
        </a>
        <a mat-list-item routerLink='/downloads' [routerLinkActive]="'active'">
          <div class="sidebar-item"><mat-icon
              matListItemIcon>download</mat-icon><span>Downloads</span></div>
        </a>
        <a mat-list-item routerLink='/workflows' [routerLinkActive]="'active'">
          <div class="sidebar-item"><mat-icon
              matListItemIcon>account_tree</mat-icon><span>Workflows</span></div>
        </a>
        <a mat-list-item [href]="analysysUrl" target="_blank">
          <div class="sidebar-item"><mat-icon matListItemIcon>query_stats</mat-icon><span>Analysis
              Hub</span>
          </div>
        </a>
        <a mat-list-item [href]="helpUrl" target="_blank">
          <div class="sidebar-item"><mat-icon matListItemIcon>help</mat-icon><span>Help</span>
          </div>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content #content class="content-container">
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>