import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { HttpAuthInterceptor } from './http-auth.interceptor';
import { APP_CONFIG } from '../assets/config';
import { Amplify } from 'aws-amplify';
import { MatDialogRef } from '@angular/material/dialog';
import { NgHttpCachingModule } from 'ng-http-caching';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: APP_CONFIG.cognitoUserPoolId,
      userPoolClientId: APP_CONFIG.cognitoUserPoolClientId,
      // Only needed for Federated Authentication
      identityPoolId: APP_CONFIG.cognitoIdentityPoolId,
      signUpVerificationMethod: 'code', // 'code' | 'link'
    },
  },
});

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(NgHttpCachingModule),
    provideRouter(
      routes,
      withViewTransitions(),
      withComponentInputBinding(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpAuthInterceptor,
    },
    provideAnimations(),
  ],
};
