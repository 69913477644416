import { Component, inject } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { MatButton } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-out',
  standalone: true,
  imports: [MatButton, MatDialogContent, MatDialogActions, MatDialogClose],
  templateUrl: './sign-out.component.html',
  styleUrl: './sign-out.component.css',
})
export class SignOutComponent {
  public authenticator = inject(AuthenticatorService);
}
