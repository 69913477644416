<form>

  <div mat-dialog-content class="dialog">
    <mat-form-field class="url-text" color=accent subscriptSizing="dynamic">
      <mat-label>Root Catalog</mat-label>
      <input matInput name="url" [(ngModel)]="data.url" cdkFocusInitial>
    </mat-form-field>
  </div>
  <mat-dialog-actions>
    <button type="submit" mat-raised-button color=primary
      [mat-dialog-close]="data.url">Modify</button>
  </mat-dialog-actions>
</form>