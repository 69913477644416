import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'signin',
    loadComponent: () => import('./shell/sign-in/sign-in.component').then((c) => c.SignInComponent),
  },
  {
    path: 'catalog',
    loadComponent: () => import('./catalog/catalog.component').then((c) => c.CatalogComponent),
  },
  {
    path: 'search',
    loadComponent: () => import('./search/search.component').then((c) => c.SearchComponent),
  },
  {
    path: 'downloads',
    loadComponent: () =>
      import('./downloads/downloads.component').then((c) => c.DownloadsComponent),
  },
  {
    path: 'workflows',
    loadComponent: () =>
      import('./workflows/workflows.component').then((c) => c.WorkflowsComponent),
  },
  {
    path: 'workflows/:id/jobs/:jobId',
    loadComponent: () =>
      import('./workflows/job-detail/job-detail.component').then((c) => c.JobDetailComponent),
  },
  {
    path: 'workflows/:id/create',
    loadComponent: () =>
      import('./workflows/workflow-start/workflow-start.component').then(
        (c) => c.WorkflowStartComponent
      ),
  },
  {
    path: 'workflows/:id',
    loadComponent: () =>
      import('./workflows/workflow-detail/workflow-detail.component').then(
        (c) => c.WorkflowDetailComponent
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./home/home.component').then((c) => c.HomeComponent),
  },
];
